.navbar {
	position: sticky;
	left: 0%;
	top: 0%;
	right: 0%;
	display: block;
	overflow: visible;
	padding-right: 0rem;
	padding-left: 0rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	background-color: #24547a;
}

.header-content-right-row {
	display: block;
	height: auto;
	margin-top: 0rem;
	margin-bottom: 0rem;
	margin-left: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-ordinal-group: 1;
	-webkit-order: 0;
	-ms-flex-order: 0;
	order: 0;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.secondary-button {
	display: block;
	height: auto;
	margin: 0rem;
	padding: 0.625em 2rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-radius: 3px;
	background-color: #1f79be;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	-webkit-transition: background-color 350ms ease;
	transition: background-color 350ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 0.875rem;
	line-height: 1.5rem;
	font-weight: 400;
	letter-spacing: 0em;
	text-transform: capitalize;
}

.secondary-button:hover {
	background-color: #11609c;
	color: #fff;
}

.hero-img-wrapper {
	display: block;
	height: 60vh;
	margin-top: -88px;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.15)), to(rgba(0, 0, 0, 0.15))), -webkit-gradient(linear, left top, left bottom, from(rgba(36, 84, 122, 0.75)), to(rgba(36, 84, 122, 0.75))), url('../images/banner-img.jpg');
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), linear-gradient(180deg, rgba(36, 84, 122, 0.75), rgba(36, 84, 122, 0.75)), url('../images/banner-img.jpg');
	background-position: 0px 0px, 0px 0px, 50% 45%;
	background-size: auto, auto, cover;
	background-repeat: repeat, repeat, no-repeat;
}

.home-content-section {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding: 0rem 0rem 3rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.home-content-section.apply {
	padding-top: 3rem;
	background-color: rgba(102, 133, 190, 0.1);
}

.home-content-section._1 {
	padding-top: 5rem;
	padding-bottom: 5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.cb {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	height: auto;
	margin-right: 0rem;
	padding-right: 2rem;
	padding-left: 2rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	background-color: transparent;
}

.cb.apply {
	display: block;
	width: 100%;
	padding-right: 0rem;
	padding-left: 0rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.cb-img {
	position: static;
	top: -65px;
	right: 0px;
	width: auto;
	height: auto;
	margin-right: 2rem;
	margin-bottom: 1.5rem;
}

.footer {
	display: block;
	border-top: 1px none #000;
	background-color: #24547a;
}

.footer-column-title {
	margin-top: 0em;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 1.125rem;
	line-height: 24px;
	font-weight: 400;
	letter-spacing: 0em;
}

.footer-col {
	margin-top: 0px;
	margin-right: 8rem;
	margin-left: auto;
	padding-top: 0rem;
}

.footer-col.last {
	margin-right: 0rem;
	margin-left: 0px;
}

.footer-col-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: none;
	margin-right: 1.25rem;
	margin-bottom: 0em;
	padding-right: 0rem;
	clear: both;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.footer-col-wrapper.logo-col {
	margin-right: 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
}

.footer-paragraph {
	margin-bottom: 1rem;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 0.9rem;
	line-height: 20px;
}

.row.footer-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.partners-container {
	display: block;
	max-width: none;
	padding: 2rem 4rem 0rem;
}

.footer-signoff-list {
	display: inline-block;
	margin: 0rem;
	padding-left: 0px;
}

.footer-container {
	display: block;
	max-width: none;
	padding-top: 0px;
	padding-right: 4rem;
	padding-left: 4rem;
}

.footer-signoff-section {
	margin-top: 0rem;
	padding-top: 2rem;
	padding-bottom: 1rem;
	background-color: transparent;
	font-size: 0.75rem;
	line-height: 1rem;
}

.footer-signoff-list-item {
	display: inline-block;
	margin-bottom: 0em;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
	border-left: 1px none #fff;
}

.footer-signoff-list-item.adobe-acrobat {
	border-left-style: none;
	border-left-color: #fff;
}

.grip-logo-text-box {
	color: #000;
	font-size: 0.5rem;
	line-height: 0.5rem;
	font-weight: 700;
	letter-spacing: 0.01rem;
	text-decoration: none;
	text-transform: uppercase;
}

.grip-logo-text-box.webdevby-white {
	color: #fff;
}

.footer-rights-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.footer-rights-left-col {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.copyright-text {
	display: inline-block;
	margin-right: 0.5rem;
	margin-left: 0rem;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 0.8rem;
}

.hero-heading {
	max-width: none;
	margin-top: 94px;
	margin-bottom: 0.5rem;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 2rem;
	line-height: 2.25rem;
	font-weight: 600;
	text-align: center;
}

.paragraph-text {
	font-family: 'Open Sans', sans-serif;
	font-size: 0.9rem;
	font-weight: 400;
}

.paragraph-text.apply-online {
	font-size: 1rem;
}

.cb-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: auto;
	padding: 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: transparent;
}

.hero-img-inner-content {
	position: relative;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	padding: 2rem 5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.heading-h2 {
	margin-top: 0px;
	margin-bottom: 0.2rem;
	font-family: 'Open Sans', sans-serif;
	color: #24547a;
	font-size: 1.75rem;
	line-height: 2rem;
	font-weight: 600;
}

.heading-h2.apply-online {
	margin-bottom: 1.5rem;
}

.hero-text-block {
	max-width: 700px;
	width: 100%;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 1rem;
	font-weight: 300;
	text-align: center;
}

.home-cbox-apply-online {
	display: block;
	margin-top: 0.5rem;
	padding: 0.625rem 2rem;
	clear: none;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	border-radius: 3px;
	background-color: #1f79be;
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 0.875rem;
	line-height: 1.5rem;
	font-weight: 400;
	text-decoration: none;
}

.home-cbox-apply-online:hover {
	background-color: #11609c;
	color: #fff;
}

.cb-inner-content-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: auto;
	padding: 3rem 2rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	background-color: transparent;
}

.cb-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-right: 1.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.div-block-9 {
	width: auto;
}

.text-block {
	max-width: none;
	margin-bottom: 1.5rem;
	font-family: 'Open Sans', sans-serif;
	color: #24547a;
	font-size: 0.9rem;
	line-height: 1rem;
	font-weight: 600;
}

.cb-image-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 1.5rem;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.horiz-divider {
	width: 450px;
	height: 2px;
	margin-right: auto;
	background-color: #1f79be;
}

.horiz-divider.mobile {
	display: none;
	margin-right: 0px;
	margin-left: auto;
}

.horiz-divider._3 {
	display: block;
	margin-right: 0px;
	margin-left: auto;
}

.apply-cb-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-right: 1.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.footer-nav-link {
	display: inline-block;
	margin-bottom: 0.25rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	border-bottom: 1px solid hsla(0, 0%, 100%, 0.15);
	-webkit-transition: border 250ms ease;
	transition: border 250ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 0.9rem;
	text-decoration: none;
	cursor: pointer;
}

.footer-nav-link:hover {
	border-bottom-color: #fff;
	text-decoration: none;
}

.footer-nav-link.signoff-section {
	margin-bottom: 0rem;
	font-size: 0.8rem;
}

.invisible-block {
	position: absolute;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: auto;
	height: 130px;
	background-color: transparent;
}

.apply-online-link {
	display: inline-block;
	margin-bottom: 0.25rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	border-bottom: 1px solid #24547a;
	-webkit-transition: border 250ms ease;
	transition: border 250ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #24547a;
	text-decoration: none;
	cursor: pointer;
}

.apply-online-link:hover {
	border-bottom-color: #1f79be;
	color: #1f79be;
	text-decoration: none;
}

.home-cb-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
}

.footer-logo {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin: 0em 1.5rem 0em 0em;
	padding-bottom: 0.25em;
	padding-left: 0.75em;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	border-bottom-style: none;
	color: #fff;
	font-size: 1.75rem;
	line-height: 1em;
	font-weight: 600;
	text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.footer-logo:hover {
	opacity: 0.75;
	color: #fff;
}

.footer-logo.footer {
	margin-right: 3rem;
	padding-left: 0em;
}

.deadline-text {
	max-width: none;
	margin-bottom: 1.5rem;
	font-family: 'Open Sans', sans-serif;
	color: #24547a;
	font-size: 0.9rem;
	line-height: 1rem;
	font-weight: 600;
}

.nav-menu-mobile-link {
	display: none;
}

.two-part-mobile-nav-wrapper {
	display: none;
}

.main-nav-link {
	position: relative;
	display: block;
	padding: 0.5rem 1.25rem;
	-webkit-transition: all 150ms ease;
	transition: all 150ms ease;
	color: #000;
	font-size: 0.9rem;
	line-height: 1.2rem;
	font-weight: 500;
	text-decoration: none;
	cursor: pointer;
}

.main-nav-link.w--current {
	background-color: rgba(3, 52, 102, 0.6);
	color: #f9b604;
}

.main-nav-link.mobile-duplicate {
	display: none;
}

.header-logo {
	width: auto;
}

.nav-header-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 0.75rem 2rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.nav-menu-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 0.625rem 2rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: 2px none transparent;
	border-radius: 3px;
	-webkit-transition: background-color 350ms ease;
	transition: background-color 350ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 0.875rem;
	line-height: 1.5rem;
	letter-spacing: 0em;
	text-transform: capitalize;
}

.nav-menu-link:hover {
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	background-color: #11609c;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	color: #fff;
}

.nav-menu-link.w--current {
	margin-bottom: -2px;
	border-bottom-style: none;
	border-bottom-color: #ae332a;
	color: #333;
}

.nav-dd {
	display: block;
	padding-bottom: 1px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	-ms-grid-row-align: stretch;
	align-self: stretch;
	border-right: 1px none rgba(0, 0, 0, 0.05);
	border-radius: 3px;
	-webkit-transition: background-color 350ms ease;
	transition: background-color 350ms ease;
}

.nav-dd:hover {
	background-color: #11609c;
}

.nav-toggle {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	margin-right: 0rem;
	margin-left: 0px;
	padding: 0.6rem 1.5rem 0.6rem 2rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transition: -webkit-transform 350ms ease;
	transition: -webkit-transform 350ms ease;
	transition: transform 350ms ease;
	transition: transform 350ms ease, -webkit-transform 350ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 0.875rem;
	line-height: 1.5rem;
	font-weight: 400;
	letter-spacing: 0rem;
	text-transform: capitalize;
}

.nav-toggle:hover {
	color: #fff;
}

.dd-text {
	padding-right: 0.5rem;
	line-height: 1rem;
}

.dd-icon {
	position: static;
	bottom: 2px;
	display: block;
	margin-top: -5px;
	margin-right: 0px;
	margin-bottom: 0px;
	padding-right: 0rem;
	background-color: transparent;
	font-size: 0.7rem;
	line-height: 1rem;
}

.nav-dd-list {
	overflow: hidden;
	height: auto;
	background-color: #fff;
	box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
}

.nav-dd-list.w--open {
	top: 64px;
	margin-top: 0px;
	padding-top: 1rem;
	padding-bottom: 1rem;
	border-top: 3px solid #11609c;
	background-color: #0a253a;
	box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.05);
}

.dd-link {
	padding: 0.75rem 3rem 0.75rem 1rem;
	-webkit-transition: background-color 250ms ease;
	transition: background-color 250ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-weight: 400;
}

.dd-link:hover {
	background-color: rgba(102, 133, 190, 0.1);
	color: #fff;
}

.nav-menu-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 1rem;
	margin-left: auto;
}

.image-2 {
	width: 320px;
}

.block-quote {
	margin-top: 10px;
	margin-bottom: 20px;
	padding: 1.5rem 2rem;
	border-left-style: none;
	border-left-color: #1f79be;
	background-color: rgba(10, 37, 58, 0.08);
	color: #333;
	font-size: 1.15rem;
	line-height: 1.6rem;
	font-style: normal;
	font-weight: 400;
}

.heading-10 {
	margin-bottom: 5px;
	color: #24547a;
	font-weight: 400;
	text-transform: uppercase;
}

.heading-6 {
	margin-top: 0px;
	font-family: 'Open Sans', sans-serif;
	color: #24547a;
	font-size: 2.5rem;
	font-weight: 400;
}

.inside-img-wrap {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 150px;
	margin-bottom: 1rem;
}

.breadcrumb-nav-link {
	border-bottom: 0.5px solid rgba(51, 51, 51, 0.05);
	font-family: 'Open Sans', sans-serif;
	color: #24547a;
	font-size: 0.9rem;
	line-height: 1rem;
	font-weight: 400;
	text-decoration: none;
	text-transform: none;
}

.breadcrumb-nav-link:hover {
	border-bottom-style: solid;
	border-bottom-color: transparent;
	color: #194669;
}

.breadcrumb-nav-link.w--current {
	color: #28313b;
	font-weight: 700;
}

.left-nav-column {
	display: block;
	padding-right: 0px;
	padding-left: 0px;
}

.table-div {
	font-size: 0.8rem;
	font-weight: 400;
}

.ordered-list {
	margin-bottom: 1.5rem;
	font-family: 'Open Sans', sans-serif;
	font-size: 1rem;
	line-height: 1.5rem;
}

.inside-page-breadcrumb-wrap {
	display: inline-block;
	width: 100%;
	margin-bottom: 2rem;
	padding-bottom: 0.5rem;
	border-bottom: 1px solid rgba(51, 51, 51, 0.05);
}

.intro-paragraph {
	margin-bottom: 1rem;
	font-family: 'Open Sans', sans-serif;
	color: #333;
	font-size: 1.1rem;
	line-height: 1.5rem;
	font-weight: 400;
}

.h5-header-link {
	display: inline-block;
	margin-bottom: 1rem;
	padding-left: 0rem;
	border-bottom: 1px solid rgba(51, 51, 51, 0.1);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #1f79be;
	font-size: 14px;
	line-height: 20px;
	font-weight: 400;
	text-decoration: none;
	text-transform: none;
}

.h5-header-link:hover {
	border-bottom-color: transparent;
	background-color: transparent;
	color: #1f79be;
}

.h5-header-link.application {
	margin-bottom: 0.5rem;
	padding-left: 1rem;
	background-image: url('../images/nav-blue-arrow.svg');
	background-position: 1% 50%;
	background-size: 5px;
	background-repeat: no-repeat;
	font-weight: 600;
}

.horizontal-divider {
	width: 100%;
	height: 1px;
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	background-color: rgba(51, 51, 51, 0.05);
	color: #003c43;
}

.primary-button {
	margin-top: 0px;
	padding: 0.75rem 2rem;
	border-radius: 3px;
	background-color: #1f79be;
	box-shadow: none;
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 0.875rem;
	line-height: 1.5rem;
	font-weight: 400;
	text-transform: none;
}

.primary-button:hover {
	border-radius: 3px;
	background-color: #11609c;
	box-shadow: none;
}

.section-wrap {
	background-color: rgba(102, 133, 190, 0.1);
}

.left-nav-nested-list-item {
	margin-bottom: 0px;
	border-bottom: 1px none rgba(51, 51, 51, 0.08);
}

.left-nav-nested-list-item.grandchild {
	border-bottom-style: none;
}

.left-nav-nested-list-item.grandchild.current {
	background-color: transparent;
}

.left-nav-nested-list-item.great-grandchild {
	border-bottom-style: none;
}

.left-nav-nested-list-item.great-grandchild.current {
	background-color: transparent;
}

.left-nav-list-content-wrap {
	display: block;
	overflow: hidden;
	height: auto;
	margin-top: 0rem;
	margin-right: 1.5rem;
	padding-top: 0.25rem;
	padding-bottom: 1rem;
	border-right: 1px none rgba(51, 51, 51, 0.1);
	border-radius: 0px;
	background-color: rgba(51, 51, 51, 0.03);
}

.h3-header-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 1px solid rgba(51, 51, 51, 0.1);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #1f79be;
	font-size: 24px;
	line-height: 30px;
	font-weight: 400;
	text-decoration: none;
}

.h3-header-link:hover {
	border-bottom-color: transparent;
	background-color: transparent;
	color: #1f79be;
}

.inside-img {
	height: 100%;
	background-image: url('../images/banner-img-BW.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.left-nav-nested-list-item-wrapper {
	margin-bottom: 0px;
	border-bottom: 1px none rgba(51, 51, 51, 0.08);
}

.paragraph {
	margin-top: 0rem;
	margin-bottom: 1.5rem;
	font-family: 'Open Sans', sans-serif;
	color: #333;
	font-size: 0.915rem;
	line-height: 1.4rem;
	font-weight: 400;
	text-decoration: none;
}

.paragraph.list-item {
	margin-bottom: 0rem;
	text-decoration: none;
}

.paragraph.list-item.unordered {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 250px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.button-styling {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 550px;
	margin-top: 1rem;
	margin-bottom: 2rem;
	padding-left: 0px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-family: 'Open Sans', sans-serif;
	font-weight: 800;
}

.h6-header-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 1px solid rgba(51, 51, 51, 0.1);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #1f79be;
	font-size: 12px;
	line-height: 18px;
	font-weight: 400;
	text-decoration: none;
	text-transform: none;
}

.h6-header-link:hover {
	border-bottom-color: transparent;
	background-color: transparent;
	color: #1f79be;
}

.breadcrumb-divider {
	margin-left: 0.5rem;
	float: right;
}

.paragraph-text-2 {
	margin-bottom: 1rem;
	font-family: 'Open Sans', sans-serif;
	color: #333;
	font-size: 1rem;
	line-height: 1.5rem;
	text-decoration: none;
}

.paragraph-text-2.list-item {
	margin-bottom: 0rem;
	font-size: 0.9rem;
}

.left-nav-list {
	margin-top: 0rem;
	margin-bottom: 0rem;
	padding-left: 0px;
	box-shadow: none;
}

.h2-header-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 1px solid rgba(51, 51, 51, 0.1);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #1f79be;
	font-size: 2rem;
	line-height: 2.25rem;
	font-weight: 400;
	text-decoration: none;
}

.h2-header-link:hover {
	border-bottom-color: transparent;
	background-color: transparent;
	color: #1f79be;
}

.left-nav-nested-list {
	margin-top: 0em;
	margin-bottom: 0em;
	padding-top: 0em;
	padding-bottom: 0em;
	border-top: 2px none #498fa3;
	background-color: rgba(228, 228, 228, 0.4);
}

.left-nav-nested-list.great-grandchild {
	background-color: rgba(228, 228, 228, 0.4);
}

.left-nav-nested-list-link {
	display: block;
	padding: 0.75em 1.5rem;
	border: 2px none transparent;
	font-family: Montserrat, sans-serif;
	color: #333;
	font-size: 0.85em;
	line-height: 1.375em;
	font-weight: 500;
	text-decoration: none;
	text-transform: capitalize;
}

.left-nav-nested-list-link:hover {
	border: 2px none #48bdd7;
	background-color: transparent;
	background-image: none;
	color: #0915f8;
}

.left-nav-nested-list-link.w--current {
	color: #5e8dbe;
}

.left-nav-nested-list-link.w--current:hover {
	color: #0915f8;
}

.left-nav-nested-list-link.great-grandchild {
	padding-left: 2.5rem;
	-webkit-transition: all 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: all 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	font-family: 'Open Sans', sans-serif;
	color: #273374;
	font-size: 0.75rem;
	line-height: 1rem;
	font-weight: 400;
}

.left-nav-nested-list-link.great-grandchild:hover {
	padding-left: 3.75rem;
	background-image: url('../images/nav-blue-arrow.svg');
	background-position: 17% 50%;
	background-size: 5px;
	background-repeat: no-repeat;
	color: #005596;
}

.left-nav-nested-list-link.great-grandchild.w--current {
	box-shadow: 9px 0 0 -16px #82bd0a;
}

.left-nav-nested-list-link.great-grandchild.current {
	margin-left: 2.25rem;
	padding-left: 1.5rem;
	background-color: transparent;
	background-image: url('../images/nav-blue-arrow.svg');
	background-position: 5% 50%;
	background-size: 5px;
	background-repeat: no-repeat;
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	color: #24547a;
	font-size: 0.75rem;
	font-weight: 600;
}

.left-nav-nested-list-link.great-grandchild.current:hover {
	padding-left: 1.75rem;
	background-position: 7% 50%;
}

.left-nav-nested-list-link.great-grandchild.current.w--current {
	margin-right: 0rem;
	background-color: transparent;
	font-family: 'Open Sans', sans-serif;
	color: #005596;
}

.left-nav-nested-list-link.grandchild {
	border-style: none;
	-webkit-transition: all 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: all 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	font-family: 'Open Sans', sans-serif;
	color: #24547a !important;
	font-size: 0.8rem !important;
	line-height: 1.15rem !important;
	font-weight: 400;
}

.left-nav-nested-list-link.grandchild:hover {
	padding-left: 2.25rem;
	background-color: transparent;
	background-image: url('../images/nav-blue-arrow.svg');
	background-position: 8% 50%;
	background-size: 5px;
	background-repeat: no-repeat;
	-webkit-transform: translate(5px, 0px);
	-ms-transform: translate(5px, 0px);
	transform: translate(5px, 0px);
	color: #005596 !important;
}

.left-nav-nested-list-link.grandchild.w--current {
	margin-left: 1rem;
	background-color: transparent;
	background-image: url('../images/nav-blue-arrow.svg');
	background-position: 4% 50%;
	background-size: 5px;
	background-repeat: no-repeat;
	font-family: 'Open Sans', sans-serif;
	color: #24547a;
	font-weight: 600;
}

.left-nav-nested-list-link.grandchild.w--current:hover {
	padding-left: 1.75rem;
	background-position: 6% 50%;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	color: #005596;
}

.h4-header-link {
	display: inline-block;
	width: auto;
	margin-bottom: 1rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	border-bottom: 1px solid rgba(51, 51, 51, 0.1);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #1f79be;
	font-size: 18px;
	line-height: 24px;
	font-weight: 400;
	text-decoration: none;
}

.h4-header-link:hover {
	border-bottom-width: 1px;
	border-bottom-color: transparent;
	background-color: transparent;
	color: #1f79be;
	font-weight: 400;
}

.breadcrumb-list {
	margin: 0rem 0.125rem;
	padding-left: 0px;
	font-size: 0.6875rem;
	line-height: 1em;
	text-transform: uppercase;
}

.left-nav-list-link {
	display: block;
	padding: 0.75rem 1.5rem 0.75rem 1rem;
	border-bottom-style: none;
	-webkit-transition: all 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	transition: all 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	font-family: 'Open Sans', sans-serif;
	color: #24547a !important;
	font-size: 0.9rem !important;
	font-weight: 400;
	text-decoration: none;
	text-transform: none;
	border-bottom: 0px !important;
}

.left-nav-list-link:hover {
	padding-left: 1.75rem;
	background-color: transparent;
	background-image: url('../images/nav-blue-arrow.svg');
	background-position: 5% 50%;
	background-size: 5px;
	background-repeat: no-repeat;
	-webkit-transform: translate(5px, 0px);
	-ms-transform: translate(5px, 0px);
	transform: translate(5px, 0px);
	color: #24547a !important;
	text-decoration: none !important;
}

.left-nav-list-link.w--current {
	padding-left: 2rem;
	border-bottom: 1px none rgba(51, 51, 51, 0.1);
	background-color: transparent;
	background-image: url('../images/nav-blue-arrow.svg');
	background-position: 7% 50%;
	background-size: 5px;
	background-repeat: no-repeat;
	color: #24547a;
	font-weight: 600;
}

.left-nav-list-link.current {
	padding-left: 2rem;
	border-bottom: 0.75px solid rgba(51, 51, 51, 0.1);
	background-image: url('../images/nav-blue-arrow.svg');
	background-position: 7% 50%;
	background-size: 5px;
	background-repeat: no-repeat;
	font-weight: 600;
}

.left-nav-list-item {
	margin-bottom: 0rem;
	border-bottom: 0.75px solid rgba(51, 51, 51, 0.1);
}

.left-nav-list-item:hover {
	background-color: transparent;
}

.main-content-column {
	padding-right: 0px;
	padding-left: 0px;
}

.breadcrumb-nav-current-page {
	font-family: 'Open Sans', sans-serif;
	color: #24547a;
	font-size: 0.9rem;
	line-height: 1rem;
	font-weight: 600;
	text-transform: none;
}

.inside-page-bg-img {
	position: relative;
	display: none;
	height: 175px;
	margin-right: 0px;
	margin-left: 0px;
	padding-top: 0px;
	padding-right: 0px;
	padding-left: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	background-color: #1f79be;
}

.main-content-section {
	max-width: none;
	margin-right: 1.5rem;
	font-family: 'Open Sans', sans-serif;
}

.main-content-section.full-page {
	margin-right: 0rem;
}

.main-content-wrapper {
	display: block;
	width: 95%;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
}

.content-container {
	display: block;
	width: 50%;
	max-width: none;
	margin-right: 0px;
	margin-left: 0px;
	padding-top: 0rem;
	padding-right: 0rem;
	padding-left: 0rem;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	background-color: #fff;
}

.content-container.threequarterwidth {
	width: 100%;
	max-width: none;
	margin-right: 0rem;
	padding-right: 0rem;
	padding-left: 0rem;
}

.inside-content-background {
	position: relative;
	display: block;
	max-width: 1300px;
	margin-top: -8px;
	margin-right: auto;
	margin-left: auto;
	padding: 3rem 0px;
	border-top: 1px none #000;
	background-color: #fff;
	min-height: 650px;
}

.breadcrumb-list-item {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0.25rem;
	margin-right: 0.5rem;
	margin-bottom: 0.25rem;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.unordered-list {
	margin-bottom: 1rem;
}

.heading-18 {
	margin-top: 0px;
	margin-bottom: 10px;
	font-family: 'Open Sans', sans-serif;
	color: #24547a;
	font-weight: 400;
}

.secondary {
	margin-top: 0px;
	padding: 0.75rem 2rem;
	border-radius: 3px;
	background-color: #24547a;
	box-shadow: none;
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 0.875rem;
	line-height: 1.5rem;
	font-weight: 400;
	text-transform: none;
}

.secondary:hover {
	border-radius: 3px;
	background-color: #194669;
	box-shadow: none;
}

.tertiary {
	margin-top: 0px;
	padding: 0.75rem 2rem;
	border-radius: 3px;
	background-color: #6b6b6b;
	box-shadow: none;
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 0.875rem;
	line-height: 1.5rem;
	font-weight: 400;
	text-transform: none;
}

.tertiary:hover {
	border-radius: 3px;
	background-color: #505050;
	box-shadow: none;
}

.right-sidebar-column {
	padding-right: 10px;
	padding-left: 0px;
}

.right-side-wrapper {
	border-left: 1px none rgba(51, 51, 51, 0.05);
}

.sidebar-section-wrapper {
	position: relative;
	margin-bottom: 2.441em;
	margin-left: 1.5rem;
	border-top: 1px none rgba(0, 0, 0, 0.15);
	font-size: 0.875rem;
	line-height: 1.5em;
}

.sidebar-section-title {
	margin-top: 0rem;
	margin-bottom: 0.75rem;
	font-size: 1.25rem;
	line-height: 1.775rem;
}

.sidebar-section-title.h2 {
	font-family: 'Open Sans', sans-serif;
	color: #24547a;
	line-height: 1.5rem;
	font-weight: 400;
}

.sidebar-list {
	padding-left: 0em;
}

.sidebar-list-item {
	margin-bottom: 0.5rem;
	padding-left: 0px;
}

.text-link {
	border-bottom: 1px solid rgba(51, 51, 51, 0.1);
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #1f79be;
	font-size: 14px;
	line-height: 1.3rem;
	text-decoration: none;
}

.text-link:hover {
	border-bottom-color: transparent;
	color: #0d70bd;
}

.text-link.w--current {
	color: #28313b;
	font-weight: 700;
}

.form-error-msg {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #c33023;
	color: #fff;
}

.form-wrapper {
	margin-bottom: 0px;
}

.form-field {
	position: relative;
	width: auto;
	margin-bottom: 0.75rem;
	padding: 4px 10px;
	font-size: 0.875rem;
}

.form-success-msg {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #2d6d0f;
	color: #fff;
}

.button-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 240px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.form-field-label {
	color: #333;
	font-weight: 600;
}

.list {
	margin-bottom: 0px;
}

.paragraph-link {
	border-bottom: 1px solid rgba(51, 51, 51, 0.1);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #1f79be;
	text-decoration: none;
}

.paragraph-link:hover {
	border-bottom-color: transparent;
}

.block-quote-attribute {
	color: #24547a;
	font-size: 0.9rem;
	line-height: 1.25rem;
	font-style: italic;
	font-weight: 600;
}

.unordered-list-items {
	margin-bottom: 1.5rem;
	padding-left: 0px;
	font-family: 'Open Sans', sans-serif;
	font-size: 1rem;
	line-height: 1.5rem;
	list-style-type: none;
}

.link {
	text-decoration: none;
}

.link-2 {
	text-decoration: none;
}

@media screen and (max-width: 991px) {
	.header-content-right-row {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: 0rem;
		margin-left: auto;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.secondary-button {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: 1rem;
		margin-left: 0rem;
		background-image: none;
	}

	.hero-img-wrapper {
		height: 60vh;
	}

	.home-content-section.apply {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.home-content-section._1 {
		padding-top: 3rem;
		padding-bottom: 0rem;
	}

	.cb {
		width: 100%;
	}

	.footer-col {
		width: auto;
		margin-right: 3rem;
		margin-bottom: 2rem;
		margin-left: 0px;
	}

	.footer-col.last {
		width: auto;
	}

	.footer-col-wrapper {
		max-width: 50%;
		margin-right: 0rem;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.footer-col-wrapper.logo-col {
		width: 100%;
		max-width: none;
		margin-bottom: 2rem;
		margin-left: 0px;
	}

	.row.footer-row {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.partners-container {
		padding-top: 2rem;
		padding-right: 3rem;
		padding-left: 3rem;
	}

	.footer-signoff-list {
		margin-right: 0rem;
		margin-left: 0rem;
	}

	.footer-container {
		padding-top: 0px;
		padding-right: 0px;
		padding-left: 0px;
	}

	.footer-signoff-section {
		padding: 0rem 3rem 1rem;
		text-align: center;
	}

	.footer-signoff-list-item {
		padding-left: 0.5rem;
	}

	.footer-signoff-list-item.adobe-acrobat {
		padding-left: 0rem;
		border-left-style: none;
	}

	.footer-rights-wrapper {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.footer-rights-left-col {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.footer-grip-logo {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.copyright-text {
		margin-bottom: 0.25rem;
		margin-left: 0rem;
	}

	.hero-heading {
		max-width: none;
	}

	.paragraph-text.apply-online {
		text-align: center;
	}

	.hero-img-inner-content {
		padding-right: 3rem;
		padding-left: 3rem;
	}

	.cb-inner-content-wrapper {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.horiz-divider.mobile {
		display: block;
		overflow: visible;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: reverse;
		-webkit-flex-direction: row-reverse;
		-ms-flex-direction: row-reverse;
		flex-direction: row-reverse;
		-webkit-box-ordinal-group: 1;
		-webkit-order: 0;
		-ms-flex-order: 0;
		order: 0;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.horiz-divider._3 {
		display: none;
		overflow: visible;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: reverse;
		-webkit-flex-direction: row-reverse;
		-ms-flex-direction: row-reverse;
		flex-direction: row-reverse;
		-webkit-box-ordinal-group: 1;
		-webkit-order: 0;
		-ms-flex-order: 0;
		order: 0;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.apply-cb-wrapper {
		margin-right: 0rem;
	}

	.home-cb-wrapper {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.footer-logo {
		margin-right: 0.5em;
		margin-left: 0.5em;
		font-size: 1.625rem;
	}

	.footer-logo.footer {
		margin-left: -0.5rem;
		padding-bottom: 0em;
	}

	.menu-button {
		margin-left: 0px;
		padding: 0px;
		border-radius: 3px;
		color: #fff;
	}

	.menu-button.w--open {
		background-color: #0a253a;
	}

	.menu-icon {
		padding: 1rem;
		border-radius: 3px;
		font-family: 'Open Sans', sans-serif;
		font-size: 2rem;
	}

	.nav-menu-mobile-link {
		display: block;
		border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
		font-family: 'Open Sans', sans-serif;
		color: #fff;
		font-size: 0.9rem;
		line-height: 1rem;
	}

	.nav-menu-mobile-link:hover {
		background-color: rgba(102, 133, 190, 0.1);
	}

	.two-part-mobile-nav-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-top: 1px none #000;
		border-bottom: 1px solid rgba(77, 76, 76, 0.25);
	}

	.two-part-mobile-nav-wrapper.top {
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-top-style: none;
		border-top-color: rgba(77, 76, 76, 0.25);
		border-bottom-style: solid;
		border-bottom-color: hsla(0, 0%, 100%, 0.1);
		font-family: Montserrat, sans-serif;
	}

	.two-part-mobile-nav-wrapper.top:hover {
		background-color: rgba(102, 133, 190, 0.1);
	}

	.main-nav-link {
		border-bottom: 1px solid rgba(0, 0, 0, 0.2);
		text-align: left;
	}

	.main-nav-link.mobile-duplicate {
		display: block;
		width: 88%;
		padding: 20px;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom-style: none;
		border-bottom-color: hsla(0, 0%, 100%, 0.1);
		background-color: transparent;
		font-family: 'Open Sans', sans-serif;
		color: #fff;
		font-size: 0.9rem;
		line-height: 1rem;
		font-weight: 400;
	}

	.main-nav-link.mobile-duplicate:hover {
		background-color: transparent;
		background-image: none;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.main-nav-link.mobile-duplicate.w--current {
		box-shadow: inset 6px 0 0 0 #f9b604;
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
		color: #f9b604;
	}

	.mobile-menu-arrow {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 12%;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-left: 1px solid rgba(77, 76, 76, 0.25);
		background-color: #0a253a;
		color: #000;
	}

	.mobile-menu-arrow:hover {
		background-color: rgba(102, 133, 190, 0.1);
	}

	.mobile-arrow {
		margin-right: 1.25rem;
		margin-left: 1.25rem;
		font-family: Montserrat, sans-serif;
		color: #fff;
		font-size: 0.9rem;
		line-height: 1em;
		font-weight: 500;
	}

	.nav-header-wrapper {
		padding-right: 1rem;
	}

	.nav-menu-link {
		display: none;
		width: 100%;
		margin-left: 0px;
		padding: 1.25rem 2rem;
		color: #373a3d;
		font-weight: 400;
	}

	.nav-dd {
		display: none;
		margin-bottom: 0px;
		padding-bottom: 0px;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.nav-toggle {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-left: 0px;
		padding-top: 1.25rem;
		padding-bottom: 1.25rem;
		padding-left: 2rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		color: #373a3d;
	}

	.dd-icon {
		position: static;
		bottom: auto;
		margin-top: 0px;
		margin-bottom: 0px;
		margin-left: 0px;
		-webkit-align-self: auto;
		-ms-flex-item-align: auto;
		-ms-grid-row-align: auto;
		align-self: auto;
	}

	.nav-dd-list {
		position: relative;
		background-color: transparent;
		box-shadow: none;
	}

	.dd-link {
		padding-left: 0px;
		color: #fff;
	}

	.nav-menu-wrap {
		margin-right: 0rem;
		background-color: #0a253a;
	}

	.inside-img-wrap {
		width: 100%;
		height: 300px;
	}

	.inside-page-breadcrumb-wrap {
		width: 100%;
		margin-bottom: 1rem;
		padding-bottom: 1rem;
		border-bottom-style: solid;
		border-bottom-color: rgba(51, 51, 51, 0.05);
	}

	.horizontal-divider {
		margin-top: 1rem;
		margin-bottom: 1rem;
	}

	.left-nav-list-content-wrap {
		overflow: hidden;
		height: 0px;
	}

	.paragraph {
		margin-bottom: 1rem;
		text-align: left;
	}

	.left-nav-list {
		margin-bottom: 1.5em;
	}

	.main-content-column {
		padding-right: 0px;
		padding-left: 0px;
	}

	.inside-page-bg-img {
		height: 175px;
		margin-right: 0px;
		margin-left: 0px;
		padding-right: 0px;
		padding-left: 0px;
	}

	.main-content-wrapper {
		width: 100%;
	}

	.content-container {
		width: 100%;
		padding-left: 0rem;
	}

	.content-container.threequarterwidth {
		width: 100%;
		padding-left: 0rem;
	}

	.inside-content-background {
		max-width: 100%;
		padding: 2rem 3rem;
	}

	.right-side-wrapper {
		border-left-style: none;
	}

	.sidebar-section-wrapper {
		margin-bottom: 2rem;
		margin-left: 1.5rem;
	}
}

@media screen and (max-width: 767px) {
	.header-content-right-row {
		margin-right: 0rem;
	}

	.secondary-button {
		margin-right: 0.5rem;
		margin-left: 0rem;
		padding: 0.5em 1.5rem;
		-webkit-box-ordinal-group: 1;
		-webkit-order: 0;
		-ms-flex-order: 0;
		order: 0;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		background-image: none;
	}

	.hero-img-wrapper {
		height: 50vh;
	}

	.footer-column-title {
		margin-bottom: 8px;
		font-size: 1rem;
	}

	.footer-col {
		margin-right: 3rem;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.footer-col.last {
		margin-right: auto;
	}

	.footer-col-wrapper {
		max-width: 50%;
		margin-right: 2rem;
		padding-right: 0rem;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.footer-col-wrapper.logo-col {
		margin-right: 0px;
	}

	.footer-paragraph {
		display: block;
		font-size: 0.8rem;
	}

	.row.footer-row {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: 0px;
		margin-left: 0px;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.partners-container {
		padding-top: 2rem;
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.footer-signoff-list {
		display: block;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		text-align: left;
	}

	.footer-container {
		padding-top: 0px;
		padding-right: 0px;
		padding-left: 0px;
	}

	.footer-signoff-section {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.footer-signoff-list-item.last {
		padding-left: 0rem;
	}

	.footer-rights-wrapper {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.footer-rights-left-col {
		margin-bottom: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.footer-grip-logo {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.copyright-text {
		text-align: left;
	}

	.hero-heading {
		max-width: 400px;
		margin-top: 90px;
		font-size: 1.75rem;
		line-height: 2rem;
	}

	.paragraph-text {
		font-size: 0.85rem;
	}

	.paragraph-text.apply-online {
		font-size: 0.9rem;
	}

	.hero-text-block {
		max-width: 400px;
		font-size: 0.9rem;
	}

	.cb-inner-content-wrapper {
		padding-right: 0rem;
		padding-left: 0rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.cb-content {
		margin-right: 0rem;
	}

	.cb-image-wrapper {
		margin-right: 0rem;
	}

	.horiz-divider {
		width: 400px;
	}

	.footer-nav-link {
		font-size: 0.8rem;
		line-height: 0.9rem;
	}

	.footer-nav-link.signoff-section {
		margin-bottom: 0.25rem;
	}

	.footer-logo {
		margin-left: 0em;
		font-size: 1.5rem;
	}

	.menu-button {
		margin-left: 0.5rem;
	}

	.menu-icon {
		margin-left: 0rem;
		padding: 0.5rem;
	}

	.main-nav-link.mobile-duplicate {
		width: 84%;
		padding-left: 20px;
	}

	.mobile-menu-arrow {
		width: 16%;
		background-color: #0a253a;
	}

	.nav-header-wrapper {
		padding-right: 0.5rem;
		padding-left: 1rem;
	}

	.nav-menu-link {
		margin-right: 0px;
	}

	.block-quote {
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.ordered-list {
		padding-left: 20px;
	}

	.inside-page-breadcrumb-wrap {
		padding-right: 0px;
		padding-left: 0px;
	}

	.intro-paragraph {
		font-size: 1.125rem;
	}

	.h5-header-link {
		font-size: 1.125rem;
	}

	.h5-header-link.application {
		font-size: 0.85rem;
	}

	.primary-button {
		margin-top: 2px;
	}

	.h3-header-link {
		font-size: 1.5rem;
	}

	.button-styling {
		width: auto;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.h2-header-link {
		font-size: 1.75rem;
	}

	.h4-header-link {
		font-size: 1.25rem;
	}

	.inside-page-bg-img {
		min-height: 80px;
	}

	.main-content-section {
		margin-right: 0rem;
	}

	.content-container.threequarterwidth {
		padding-right: 0rem;
	}

	.inside-content-background {
		padding: 2rem 2.5rem;
	}

	.unordered-list {
		padding-left: 20px;
	}

	.secondary {
		margin-top: 2px;
	}

	.tertiary {
		margin-top: 2px;
	}

	.sidebar-section-wrapper {
		margin-top: 1rem;
		margin-bottom: 1rem;
		margin-left: 0rem;
	}

	.text-link {
		font-size: 0.85rem;
	}

	.unordered-list-items {
		padding-left: 0px;
	}

	.footer-logo.footer img {
		width: 275px;
	}

	.image-2 {
		width: 275px;
	}
}

@media screen and (max-width: 479px) {
	.header-content-right-row {
		width: 100%;
		height: auto;
		margin-top: 0rem;
		margin-bottom: 0rem;
		margin-left: 0px;
		padding: 0.25rem 0.5rem 0.25rem 1rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		border-bottom: 1px solid rgba(10, 37, 58, 0.1);
		background-color: #fff;
	}

	.secondary-button {
		display: block;
		width: auto;
		font-size: 0.875rem;
	}

	.hero-img-wrapper {
		height: 65vh;
	}

	.home-content-section._1 {
		display: block;
		padding-top: 2rem;
	}

	.cb-img {
		margin-right: 0rem;
		margin-bottom: 1rem;
	}

	.footer-column-title {
		text-align: center;
	}

	.footer-col {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		margin-right: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.footer-col.last {
		margin-right: 0px;
	}

	.footer-col-wrapper {
		max-width: 100%;
		margin-top: 1rem;
		margin-right: 0rem;
		padding-right: 0rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-flex-basis: 100%;
		-ms-flex-preferred-size: 100%;
		flex-basis: 100%;
	}

	.footer-col-wrapper.logo-col {
		max-width: 100%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.footer-paragraph {
		text-align: center;
	}

	.row.footer-row {
		margin-right: 0px;
		margin-left: 0px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.partners-container {
		display: block;
		padding-top: 2rem;
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.footer-signoff-list {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		text-align: center;
	}

	.footer-container {
		display: block;
		padding-top: 0px;
		padding-right: 0px;
		padding-left: 0px;
	}

	.footer-signoff-section {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-bottom: 1rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.footer-signoff-list-item {
		margin-bottom: 0.25rem;
		border-left-style: none;
	}

	.footer-signoff-list-item.adobe-acrobat {
		margin-bottom: 0.25rem;
		border-left-style: none;
	}

	.footer-signoff-list-item.last {
		padding-right: 0rem;
	}

	.footer-rights-wrapper {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.footer-rights-left-col {
		padding-bottom: 0.5rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.footer-grip-logo {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.copyright-text {
		margin-right: 0rem;
		margin-bottom: 0.5rem;
		text-align: center;
	}

	.hero-heading {
		margin-top: 160px;
		font-size: 1.25rem;
		line-height: 1.5rem;
	}

	.paragraph-text {
		text-align: center;
	}

	.paragraph-text.apply-online {
		text-align: center;
	}

	.hero-img-inner-content {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.heading-h2 {
		font-size: 1.75rem;
		line-height: 2rem;
		text-align: center;
	}

	.hero-text-block {
		line-height: 1.25rem;
	}

	.cb-inner-content-wrapper {
		width: 100%;
		padding-top: 2rem;
		padding-bottom: 2rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.text-block {
		margin-bottom: 1rem;
		text-align: center;
	}

	.cb-image-wrapper {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.horiz-divider {
		width: 225px;
	}

	.home-cb-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.footer-logo.footer {
		margin-right: 0rem;
		margin-left: 0rem;
	}

	.menu-button {
		margin-left: auto;
	}

	.menu-icon {
		padding: 0.5rem;
		color: #24547a;
	}

	.deadline-text {
		margin-bottom: 1rem;
		text-align: center;
	}

	.main-nav-link.mobile-duplicate {
		width: 80%;
	}

	.mobile-menu-arrow {
		position: relative;
		width: 20%;
	}

	.header-logo {
		width: 275px;
		margin-right: 1rem;
		margin-bottom: 1rem;
		margin-left: 0.98rem;
		align-self: flex-start;
	}

	.nav-header-wrapper {
		padding-right: 0rem;
		padding-bottom: 0rem;
		padding-left: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.nav-menu-link {
		display: none;
		width: 100%;
		height: 35px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		font-size: 0.875rem;
		line-height: 80%;
		text-align: center;
	}

	.nav-menu-link.w--current {
		border-bottom-style: solid;
		border-bottom-color: transparent;
		background-image: linear-gradient(135deg, #081a35, #22447a);
		color: #e8c18c;
	}

	.nav-dd {
		margin-bottom: 10px;
	}

	.nav-toggle {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 35px;
		padding-top: 10px;
		padding-right: 0px;
		padding-bottom: 0px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.dd-text {
		text-align: center;
	}

	.dd-icon {
		position: absolute;
		margin-top: 10px;
		margin-right: 20px;
		margin-left: 0px;
	}

	.dd-link {
		padding-right: 0px;
		text-align: center;
	}

	.block-quote {
		-webkit-align-self: auto;
		-ms-flex-item-align: auto;
		-ms-grid-row-align: auto;
		align-self: auto;
	}

	.heading-10 {
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
		text-align: center;
	}

	.heading-6 {
		font-size: 2rem;
		line-height: 2.5rem;
		text-align: center;
	}

	.inside-img-wrap {
		width: 100%;
		height: 300px;
	}

	.ordered-list {
		-webkit-align-self: auto;
		-ms-flex-item-align: auto;
		-ms-grid-row-align: auto;
		align-self: auto;
	}

	.ordered-list.unordered-list {
		padding-left: 0px;
	}

	.intro-paragraph {
		line-height: 1.5rem;
		text-align: center;
	}

	.h5-header-link {
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
		text-align: center;
	}

	.h5-header-link.application {
		display: block;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		font-size: 0.85rem;
		text-decoration: none;
	}

	.horizontal-divider {
		display: block;
	}

	.primary-button {
		margin-top: 0px;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
		font-size: 0.8rem;
		line-height: 1rem;
		font-weight: 400;
	}

	.h3-header-link {
		display: inline-block;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
		text-align: center;
	}

	.paragraph {
		font-size: 0.9rem;
		line-height: 1.25rem;
		text-align: center;
	}

	.paragraph.list-item {
		text-align: center;
	}

	.paragraph.list-item.unordered {
		width: auto;
	}

	.button-styling {
		width: auto;
		height: 175px;
		padding-left: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.h6-header-link {
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
		text-align: center;
	}

	.paragraph-text-2 {
		font-size: 0.9rem;
	}

	.paragraph-text-2.list-item {
		text-align: center;
	}

	.h2-header-link {
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
		text-align: center;
	}

	.h4-header-link {
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
		text-align: center;
	}

	.breadcrumb-nav-current-page {
		color: #005596;
		font-size: 0.9rem;
	}

	.inside-page-bg-img {
		height: 125px;
	}

	.main-content-section.full-page {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.content-container.threequarterwidth {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.inside-content-background {
		margin-top: 0px;
		padding: 1.1rem 1.5rem 2rem 1.5rem;
	}

	.heading-18 {
		text-align: center;
	}

	.secondary {
		margin-top: 0px;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
		font-size: 0.8rem;
		line-height: 1rem;
		font-weight: 400;
	}

	.tertiary {
		margin-top: 0px;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
		font-size: 0.8rem;
		line-height: 1rem;
		font-weight: 400;
	}

	.sidebar-section-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.sidebar-section-title.h2 {
		text-align: center;
	}

	.sidebar-list-item {
		text-align: center;
	}

	.text-link {
		-webkit-align-self: flex-start;
		-ms-flex-item-align: start;
		align-self: flex-start;
		font-size: 0.85rem;
	}

	.list {
		padding-left: 0px;
	}

	.text-link-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.unordered-list-items {
		padding-left: 0px;
		-webkit-align-self: auto;
		-ms-flex-item-align: auto;
		-ms-grid-row-align: auto;
		align-self: auto;
	}
}

/****************** Tablesaw ***********************/
/****************************************************/
@media (max-width: 39.9375em) {
	.tablesaw.styled tr {
		border-bottom: 2px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr td {
		border-right: 0px solid;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr:last-child,
	.tablesaw.styled tr:last-child td:last-child {
		border-bottom: none;
	}
}

/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and ( min-width: 991px ) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and ( min-width: 767px ) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/****************** Print ***********************/
/*************************************************/
#printFooter {
	display: none;
	visibility: hidden;
}

#printHeader {
	display: none;
	visibility: hidden;
}

/*********************** Header **********************/
/*****************************************************/
.nav-menu-wrap {
	margin-bottom: 0px;
}

.image-2 {
	min-width: 273px;
	transition: all 0.5s;
}


@media (min-width: 992px) {
	.navbar.sticky .image-2 {
		min-width: 255px;
		width: 255px;
		margin-left: 20px;
	}

	.navbar.sticky .nav-header-wrapper {
		padding-bottom: 0.5rem;
	}

	.navbar.sticky .accesible-navigation-menu li.nav-dd.open .container-wrapper {
		padding-top: 13px;
	}
}

@media (max-width: 1094px) and (min-width: 992px) {
	.inside-content-background {
		margin-top: -12px;
	}
}

/****************** Accesible menu **********************/
/*****************************************************/
.accesible-navigation-menu {
	display: flex;
	flex-grow: 1;
}

.accesible-navigation-menu ul,
.accesible-navigation-menu ul li {
	margin-top: 0px;
	margin-bottom: 0px;
	padding: 0px;
}

.accesible-navigation-menu li.nav-dd a.nav-toggle {
	white-space: normal;
	text-decoration: none;
	text-align: center;
	padding: 0.6rem 2rem 0.6rem 2rem;
}

.accesible-navigation-menu li.nav-dd.hasChildren a.nav-toggle {
	padding-right: 1.5rem;
}

.accesible-navigation-menu li.nav-dd.hasChildren a.nav-toggle:after {
	font-family: 'webflow-icons' !important;
	content: "\e603";
	padding-left: 6px;
	font-size: 11px;
}

.accesible-navigation-menu li.nav-dd#menu-item-resources {
	min-width: 206px;
}

.accesible-navigation-menu li.nav-dd .container-wrapper {
	opacity: 0;
	max-height: 0px;
	position: absolute;
	top: 100%;
	z-index: 100;
	left: 0;
	transition: max-height 100ms ease 0s, opacity 200ms ease 0s;
	height: 0;
}

.accesible-navigation-menu li.nav-dd .container-wrapper ul {
	display: none;
	padding: 0;
	margin: 0px;
	list-style-type: none;
	min-width: 230px;
	padding-top: 1rem;
	padding-bottom: 1rem;
	border-top: 3px solid #11609c;
	background-color: #0a253a;
	box-shadow: 1px 1px 10px 0 rgb(0 0 0 / 5%);
}

.accesible-navigation-menu li.nav-dd .container-wrapper ul a {
	padding: 0.75rem 3rem 0.75rem 1rem;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-weight: 400;
	display: inline-block;
	width: 100%;
	background-color: transparent;
	text-align: left;
	text-decoration: none;
}

.accesible-navigation-menu li.nav-dd .container-wrapper ul a:hover,
.accesible-navigation-menu li.nav-dd .container-wrapper ul a.w--current {
	background-color: rgba(102, 133, 190, 0.1);
	color: #fff;
	display: inline-block;
}

.accesible-navigation-menu li.nav-dd.open .container-wrapper {
	opacity: 1;
	max-height: 500px;
	height: auto;
	padding-top: 22px;
}

.accesible-navigation-menu li.nav-dd.open .container-wrapper ul {
	display: inline-block;
}

@media (max-width: 991px) {
	.accesible-navigation-menu {
		display: none !important;
	}
}

@media (max-width: 1094px) and (min-width: 992px) {
	.accesible-navigation-menu li.nav-dd.open .container-wrapper {
		padding-top: 12px;
	}
}

/****************** Mobile menu **********************/
/*****************************************************/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}

	.mobile-menu-button {
		display: none;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}
}

.mobile-navigation-menu li[data-breakpoints] {
	display: none !important;
}

@media screen and ( min-width: 991px ) {
	.mobile-navigation-menu li[data-breakpoints*="xl"] {
		display: flex !important;
	}
}

@media screen and ( min-width: 767px ) {
	.mobile-navigation-menu li[data-breakpoints*="md,lg"] {
		display: flex !important;
	}
}

.mobile-navigation-menu li[data-breakpoints*="xs,sm"] {
	display: flex !important;
}

.mobile-navigation-menu,
.mobile-navigation-menu .mm-panels,
.mobile-navigation-menu .mm-panel,
.mobile-navigation-menu .mm-navbar {
	background-color: #0a253a !important;
}

.mobile-navigation-menu li a.nav-link,
.mobile-navigation-menu .mm-navbar .mm-navbar__title,
.mobile-navigation-menu .mm-navbar .mm-navbar__title:hover {
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 0.9rem;
	line-height: 1rem;
	font-weight: 400;
	justify-content: flex-start;
}

.mobile-navigation-menu .mm-navbar {
	min-height: 60px;
	border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
}

.mm-listitem:after {
	border-color: hsla(0, 0%, 100%, 0.1);
	left: 0;
}

.mm-listitem__btn {
	border-color: hsla(0, 0%, 100%, 0.1);
}

.mm-btn_next:after,
.mm-btn_prev:before {
	border-color: #fff;
}

.mobile-navigation-menu > .mm-panels > .mm-panel:first-child > .mm-navbar {
	display: none;
}

.mobile-navigation-menu li a.nav-link:hover,
.mobile-navigation-menu li.active a.nav-link {
	background-color: rgba(102, 133, 190, 0.1);
}

.mm-btn_next:hover {
	background-color: rgba(102, 133, 190, 0.1);
}

/********************* Homepage *********************/
/*****************************************************/
.hero-heading a,
.heading-h2 a,
.hero-heading a:hover,
.heading-h2 a:hover {
	color: inherit;
	line-height: inherit;
	font-size: inherit;
	font-weight: inherit;
	font-size: inherit;
	text-decoration: none;
	border-bottom: 0px;
}

.hero-content p {
	font-family: inherit;
	line-height: inherit;
	font-size: inherit;
	font-weight: inherit;
	color: inherit;
	text-align: center;
}

.hero-content h1,
.hero-content h2,
.hero-content h3,
.hero-content h4,
.hero-content h5,
.hero-content h6,
.hero-content p,
.hero-content a,
.hero-content li,
.hero-content span,
.hero-content td {
	color: #fff;
}

.home-content-section.apply .cb-text {
	text-align: center;
	width: 100%;
}

.home-content-section.apply .cb-text p {
	margin-bottom: 10px;
	font-size: 1rem;
	text-align: center;
}

.home-content-section.apply .cb-text a:not(.blue-button) {
	border-bottom: 1px solid #24547a;
	-webkit-transition: border 250ms ease;
	transition: border 250ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #24547a;
	text-decoration: none;
	cursor: pointer;
}


.home-content-section.apply .cb-text a:not(.blue-button):hover {
	border-bottom-color: #1f79be;
	color: #1f79be;
	text-decoration: none;
}

.cb-text strong {
	font-weight: 600;
}


@media (max-width: 991px) {
	.hero-heading {
		margin-top: 90px;
	}
}

@media (max-width: 767px) {
	.hero-heading {
		margin-top: 100px;
	}

	.home-content-section.apply .cb-text p {
		font-size: 0.9rem;
	}
}

@media (max-width: 479px) {
	.hero-img-wrapper {
		margin-top: -144px;
	}

	.hero-heading {
		margin-top: 175px;
	}
}

/*********************** Slideshow ********************/
/*****************************************************/

/****************** Inside Page ******************/
/*************************************************/
.right-sidebar-column td img {
	max-width: none;
}

.breadcrumb-list li:last-child .breadcrumb-divider {
	display: none;
}

.breadcrumb-list li:last-child .breadcrumb-nav-link {
	font-family: 'Open Sans', sans-serif;
	color: #24547a;
	font-size: 0.9rem;
	line-height: 1rem;
	font-weight: 600;
	text-transform: none;
	text-decoration: none;
	border-bottom: 0px;
}

.left-nav-list-link.active {
	padding-left: 2rem;
	border-bottom: 1px none rgba(51, 51, 51, 0.1);
	background-color: transparent;
	background-image: url("../images/nav-blue-arrow.svg");
	background-position: 7% 50%;
	background-size: 5px;
	background-repeat: no-repeat;
	color: #24547a;
	font-weight: 600;
}

.left-nav-nested-list-link.grandchild.active {
	margin-left: 1rem;
	background-color: transparent;
	background-image: url("../images/nav-blue-arrow.svg");
	background-position: 4% 50%;
	background-size: 5px;
	background-repeat: no-repeat;
	font-family: 'Open Sans', sans-serif;
	color: #24547a;
	font-weight: 600;
}

.left-nav-nested-list-link.great-grandchild.active {
	margin-left: 2.25rem;
	padding-left: 1.5rem;
	background-color: transparent;
	background-image: url("../images/nav-blue-arrow.svg");
	background-position: 5% 50%;
	background-size: 5px;
	background-repeat: no-repeat;
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	color: #24547a;
	font-size: 0.75rem;
	font-weight: 600;
}

.main-content-wrapper.no-columns {
	width: 100%;
	max-width: none;
	margin-right: 0px;
	margin-left: 0px;
	padding-right: 90px;
	padding-left: 90px;
}

@media (max-width: 991px) {
	.main-content-wrapper.no-columns {
		padding-right: 10px;
		padding-left: 10px;
	}
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

/*************** Right Sidebar ********************/
/*************************************************/
.right-sidebar-column .box {
	margin-bottom: 2.441em;
	margin-left: 1.5rem;
	border-top: 1px none rgba(0, 0, 0, 0.15);
	font-size: 0.875rem;
	line-height: 1.5em;
}

.right-sidebar-column .box p,
.right-sidebar-column .box li,
.right-sidebar-column .box td,
.right-sidebar-column .box a,
.right-sidebar-column .box span {
	font-family: 'Open Sans', sans-serif;
	font-size: 0.915rem;
	line-height: 1.4rem;
	font-weight: 400;
}

.right-sidebar-column .box p {
	margin-top: 0rem;
	margin-bottom: 1.5rem;
}

.right-sidebar-column .box h4,
.right-sidebar-column .box h3 {
	font-family: 'Open Sans', sans-serif;
	color: #24547a;
	line-height: 1.5rem;
	font-weight: 400;
	margin-top: 0rem;
	margin-bottom: 0.75rem;
	font-size: 1.25rem;
}

.right-sidebar-column .box td {
	vertical-align: top;
	padding-bottom: 12px;
}

.right-sidebar-column .box .contentbox_item_image {
	width: 24px;
}

.right-sidebar-column .box.documents td:first-child {
	padding-right: 7px;
}

.right-sidebar-column .box.tools td:first-child img {
	margin-right: 5px;
}

.right-sidebar-column .box.links p {
	margin-bottom: 8px;
}

.right-sidebar-column .box.documents a,
.right-sidebar-column .box.links a {
	font-size: 14px;
}

@media (max-width: 767px) {
	.right-sidebar-column {
		margin-top: 20px;
	}

	.right-sidebar-column .box {
		margin-left: 0px;
	}

	.right-sidebar-column .box.documents a,
	.right-sidebar-column .box.links a {
		font-size: 0.85rem;
	}
}

@media screen and (max-width: 479px) {
	.right-sidebar-column .box h4,
	.right-sidebar-column .box h3 {
		text-align: center;
	}

	.right-sidebar-column .box table {
		margin-left: auto;
		margin-right: auto;
	}
}

/****************** Inside Page Styles ***************/
/*****************************************************/
.inside-row a,
.cb-text a {
	border-bottom: 1px solid rgba(51, 51, 51, 0.1);
	transition: all 350ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #1f79be;
	text-decoration: none;
}

.inside-row a:hover,
.cb-text a:hover {
	border-bottom-color: transparent;
	color: #0d70bd;
}

h1 {
	font-family: 'Open Sans', sans-serif;
	color: #24547a;
	font-size: 2.5rem;
	font-weight: 400;
}

h2 {
	margin-top: 0px;
	margin-bottom: 10px;
	font-family: 'Open Sans', sans-serif;
	color: #24547a;
	font-weight: 400;
}

h3 {
	margin-top: 0px;
	margin-bottom: 10px;
	font-family: 'Open Sans', sans-serif;
	color: #24547a;
	font-weight: 400;
}

h4 {
	margin-top: 0px;
	margin-bottom: 10px;
	font-family: 'Open Sans', sans-serif;
	color: #24547a;
	font-weight: 400;
}

h5 {
	margin-bottom: 5px;
	color: #24547a;
	font-weight: 400;
	text-transform: uppercase;
}

h6 {
	margin-bottom: 5px;
	color: #24547a;
	font-weight: 400;
	text-transform: uppercase;
}

h1 a {
	margin-bottom: 1rem;
	border-bottom: 1.5px solid rgba(0, 0, 36, 0.15);
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #1f79be;
	font-size: inherit;
	line-height: inherit;
	font-weight: 400;
	text-decoration: none;
}

h2 a {
	margin-bottom: 1rem;
	border-bottom: 1px solid rgba(51, 51, 51, 0.1);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #1f79be;
	font-size: 2rem;
	line-height: 2.25rem;
	font-weight: 400;
	text-decoration: none;
}

h3 a {
	margin-bottom: 1rem;
	border-bottom: 1px solid rgba(51, 51, 51, 0.1);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #1f79be;
	font-size: 24px;
	line-height: 30px;
	font-weight: 400;
	text-decoration: none;
}

h4 a {
	margin-bottom: 1rem;
	border-bottom: 1px solid rgba(51, 51, 51, 0.1);
	transition: all 200ms ease;
	color: #1f79be;
	font-size: 18px;
	line-height: 24px;
	font-weight: 400;
	text-decoration: none;
}

h5 a {
	margin-bottom: 1rem;
	padding-left: 0rem;
	border-bottom: 1px solid rgba(51, 51, 51, 0.1);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #1f79be;
	font-size: 14px;
	line-height: 20px;
	font-weight: 400;
	text-decoration: none;
	text-transform: none;
}

h6 a {
	margin-bottom: 1rem;
	border-bottom: 1px solid rgba(51, 51, 51, 0.1);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #1f79be;
	font-size: 12px;
	line-height: 18px;
	font-weight: 400;
	text-decoration: none;
	text-transform: none;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
h1:hover a,
h2:hover a,
h3:hover a,
h4:hover a,
h5:hover a,
h6:hover a {
	border-bottom-color: transparent;
	background-color: transparent;
	color: #1f79be;
}

p {
	margin-top: 0rem;
	margin-bottom: 1.5rem;
	font-family: 'Open Sans', sans-serif;
	color: #333;
	font-size: 0.915rem;
	line-height: 1.4rem;
	font-weight: 400;
	text-decoration: none;
}


@media screen and (max-width: 991px) {
	p {
		margin-bottom: 1rem;
		text-align: left;
	}
}

@media screen and (max-width: 767px) {
	h2 a {
		font-size: 1.75rem;
	}

	h3 a {
		font-size: 1.5rem;
	}

	h4 a {
		font-size: 1.25rem;
	}

	h5 a {
		font-size: 1.125rem;
	}
}

@media screen and (max-width: 479px) {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		text-align: center;
	}

	h1 {
		font-size: 2rem;
		line-height: 2.5rem;
		text-align: center;
	}

	p {
		font-size: 0.9rem;
		line-height: 1.25rem;
		text-align: center;
	}
}

/* buttons */
.inside-page-container .button,
.inside-page-container a.primary,
.primary,
.inside-page-container button[type="submit"],
.inside-page-container .cms_form_button.primary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary,
.ui-form-buttons .ui-form-button button,
.module-search button {
	display: inline-block;
	text-decoration: none;
	background-image: none;
	padding: 0.75rem 2rem;
	border-radius: 3px;
	background-color: #1f79be;
	box-shadow: none;
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #fff !important;
	font-size: 0.875rem;
	line-height: 1.5rem;
	font-weight: 400;
	text-transform: none;
}

.primary:hover,
.inside-page-container a.primary:hover .inside-page-container button[type="submit"]:hover,
.inside-page-container .cms_form_button.primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover,
.ui-form-buttons .ui-form-button button:hover,
.module-search button:hover {
	border-radius: 3px;
	background-color: #11609c !important;
	box-shadow: none;
	color: #fff !important;
}

.secondary,
.inside-page-container a.secondary,
.inside-page-container button[type="submit"].secondary,
.cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary,
.inside-page-container .cms_form_button.secondary,
.inside-page-container .button.secondary {
	display: inline-block;
	padding: 0.75rem 2rem;
	border-radius: 3px;
	background-color: #24547a;
	box-shadow: none;
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #fff !important;
	font-size: 0.875rem;
	line-height: 1.5rem;
	font-weight: 400;
	text-transform: none;
}

.secondary:hover,
.inside-page-container a.secondary:hover,
.inside-page-container button[type="submit"].secondary:hover,
.cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover,
.inside-page-container .cms_form_button.secondary:hover,
.inside-page-container .button:hover {
	border-radius: 3px;
	background-color: #194669;
	box-shadow: none;
	color: #fff !important;
}

.tertiary,
.inside-page-container button[type="submit"].tertiary,
.cms_form_button.tertiary,
.inside-page-container a.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	display: inline-block;
	padding: 0.75rem 2rem;
	border-radius: 3px;
	background-color: #6b6b6b;
	box-shadow: none;
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #fff !important;
	font-size: 0.875rem;
	line-height: 1.5rem;
	font-weight: 400;
	text-transform: none;
}

.tertiary:hover,
.inside-page-container a.tertiary:hover,
.inside-page-container button[type="submit"].tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover {
	border-radius: 3px;
	background-color: #505050;
	box-shadow: none;
	color: #fff !important;
}

@media screen and (max-width: 479px) {
	.inside-page-container .button,
	.inside-page-container a.primary,
	.primary,
	.inside-page-container button[type="submit"],
	.inside-page-container .cms_form_button.primary,
	#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary,
	#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary,
	.ui-form-buttons .ui-form-button button,
	.module-search button {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
		font-size: 0.8rem;
		line-height: 1rem;
		font-weight: 400;
	}

	.secondary,
	.inside-page-container a.secondary,
	.inside-page-container button[type="submit"].secondary,
	.cms_form_button.secondary,
	#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary,
	.inside-page-container .cms_form_button.secondary,
	.inside-page-container .button.secondary {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
		font-size: 0.8rem;
		line-height: 1rem;
		font-weight: 400;
	}

	.tertiary,
	.inside-page-container button[type="submit"].tertiary,
	.cms_form_button.tertiary,
	.inside-page-container a.tertiary,
	#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
		margin-top: 0px;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
		font-size: 0.8rem;
		line-height: 1rem;
		font-weight: 400;
	}
}


/* messages */
#message.success,
#polls .session_message.success {
	background-color: #2d6d0f;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	color: #fff;
}

#message.error,
#polls .session_message.error {
	background-color: #c33023;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	color: #fff;
}

#message.error p,
#message.success p {
	color: #fff;
}

#message.error p:last-child,
#message.success p:last-child {
	margin-bottom: 0;
}

/* tables */
table.styled {
	margin: 0 0 1em;
	background-color: white;
	border-spacing: 0px;
}

table.styled th,
table.styled td {
	border-right: 1px solid white;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #24547a;
	opacity: 100%;
	color: white;
}

table.styled thead tr th h1,
table.styled thead tr th h2,
table.styled thead tr th h3,
table.styled thead tr th h4,
table.styled thead tr th h5,
table.styled thead tr th h6 {
	color: inherit;
	margin: 0;
}

table.styled th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: white;
	opacity: 100%;
	color: #333333;
}

table.styled th a,
table.styled th a:visited {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
	border-bottom: 0px;
	background-color: transparent;
}

table.styled th a:hover {
	color: #fff;
	text-decoration: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
}

table.styled.striped tr:nth-child(even) {
	background: rgba(10, 37, 58, 0.08);
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

/* forms */
.inside-row input[type="text"],
.inside-row input[type="tel"],
.inside-row input[type="password"],
.inside-row input[type="email"],
.inside-row select,
.inside-row textarea,
.inside-row input[type="search"] {
	max-width: 100%;
	margin-bottom: 0.75rem;
	padding: 4px 10px;
	font-size: 0.875rem;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #666;
}

.inside-row textarea {
	resize: vertical;
}

.inside-row label {
	font-weight: 400;
}

@media (max-width: 991px) {
	.inside-row input[type="text"],
	.inside-row input[type="tel"],
	.inside-row input[type="password"],
	.inside-row input[type="email"],
	.inside-row select,
	.inside-row textarea,
	.inside-row input[type="search"] {
		width: 100%;
	}
}

/*blockquotes*/
blockquote {
	border-left: 5px solid #e2e2e2;
	margin: 0 0 10px 0;
	margin-top: 10px;
	margin-bottom: 20px;
	padding: 1.5rem 2rem;
	border-left-style: none;
	border-left-color: #1f79be;
	background-color: rgba(10, 37, 58, 0.08);
	color: #333;
	font-size: 1.15rem;
	line-height: 1.6rem;
	font-style: normal;
	font-weight: 400;
}

blockquote p {
	font-size: inherit;
	line-height: inherit;
	margin-bottom: 0;
	margin-top: 0;
}

@media screen and (max-width: 767px) {
	blockquote {
		font-size: 1rem;
		line-height: 1.5rem;
	}
}

/*pagination*/
.pagination-wrapper {
	font-size: 0.9rem;
}

.pagination-wrapper .pagination-previous-page .disabled,
.pagination-wrapper .pagination-next-page .disabled {
	color: #333333;
	font-style: italic;
}

#pagination span {
	color: #333 !important;
}

/*skip to main content WCAG link*/
.cms_form_button.primary.skip-to-main-content.sronly {
	border: 0px !important;
	padding: 0px !important;
	background: none !important;
}

.cms_form_button.primary.skip-to-main-content.sronly:focus {
	text-indent: 0;
	width: auto;
	height: auto;
	box-shadow: 0 0 5px #000;
	z-index: 2000;
	background-color: #fff !important;
	padding: 5px 10px !important;
	color: #333 !important;
}

/*********************** Footer **********************/
/*****************************************************/
.footer-nav-link.desktop {
	display: inline-block;
	cursor: default;
	border: 0px;
}

.footer-nav-link.desktop:hover {
	border-bottom: 0px;
	text-decoration: none;
}

.footer-nav-link.mobile {
	display: none;
}

#GRIPFooterLogo {
	line-height: 11px;
	padding-top: 0px !important;
}

@media ( max-width: 991px ) {
	.footer-nav-link.desktop {
		display: none;
	}

	.footer-nav-link.mobile {
		display: inline-block;
	}
}

/*********************** Modules ********************/
/*****************************************************/
/* Submission forms */
#submissionforms_module.cms_form .required_information em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_required em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 2px;
	font-weight: normal;
	font-size: 0.9rem;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span a {
	border-bottom: 0px;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .sigPad input,
#submissionforms_module.cms_form .sigWrapper.current {
	border-color: #666;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .checkbox_wrapper .item label,
#submissionforms_module.cms_form .radio_wrapper .item label {
	margin: 0px;
	font-size: 0.9rem;
	line-height: normal;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}


/******************* Content Boxes *******************/
/*****************************************************/
/* submission forms */
.slider #submissionforms_module.homepage_contentbox {
	padding: 110px 20px 20px 40px;
}

#submissionforms_module.homepage_contentbox {
	text-align: left;
	line-height: 1.5rem;
	font-size: 1rem;
}

#submissionforms_module.homepage_contentbox table {
	width: auto;
}

#submissionforms_module.homepage_contentbox input[type="text"],
#submissionforms_module.homepage_contentbox input[type="tel"],
#submissionforms_module.homepage_contentbox input[type="email"],
#submissionforms_module.homepage_contentbox select,
#submissionforms_module.homepage_contentbox textarea {
	width: 100%;
	max-width: 100%;
	padding: 8px 12px;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #ccc;
	font-size: 0.9rem;
	margin-bottom: 10px;
}

#submissionforms_module.homepage_contentbox textarea {
	width: 100% !important;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 5px;
}

#submissionforms_module.homepage_contentbox input[type="checkbox"],
#submissionforms_module.homepage_contentbox input[type="radio"] {
	margin-right: 3px;
}

#submissionforms_module.homepage_contentbox .sigWrapper .pad {
	max-width: 100%;
}
